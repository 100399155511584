import axios from "axios";
import { BACKEND } from "../constant/constants";

export const baseGet = (api: string) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res = await axios.get(BACKEND + "api/" + api);
            const payload = JSON.parse(res.data);
            
            if (payload.Command == 2)
                resolve(payload.Data);
            else
                reject(payload.Data);
        } catch (ex: any) {
            console.log(ex);
            reject(JSON.parse(ex.response.data).Data);
        }
    });
}

export const basePost = (api: string, data: any) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res = await axios.post(BACKEND + "api/" + api, data);
            const payload = JSON.parse(res.data);

            if (payload.Command == 2)
                resolve(payload.Data);
            else
                reject(payload.Data);

        } catch (ex: any) {
            reject(JSON.parse(ex.response.data).Data);
        }
    });
}

export const basePatch = (api: string, data: any) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res = await axios.patch(BACKEND + "api/" + api, data);
            const payload = JSON.parse(res.data);

            if (payload.Command == 2)
                resolve(payload.Data);
            else
                reject(payload.Data);
        } catch (ex: any) {
            reject(JSON.parse(ex.response.data).Data);
        }
    });
}


export const baseDelete = (api: string) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res = await axios.delete(BACKEND + "api/" + api);
            const payload = JSON.parse(res.data);

            if (payload.Command == 2)
                resolve(payload.Data);
            else
                reject(payload.Data);
        } catch (ex: any) {
            reject(JSON.parse(ex.response.data).Data);
        }
    });
}

export const basePut = (api: string, data: any) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res = await axios.put(BACKEND + "api/" + api, data);
            const payload = JSON.parse(res.data);

            if (payload.Command == 2)
                resolve(payload.Data);
            else
                reject(payload.Data);
        } catch (ex: any) {
            reject(JSON.parse(ex.response.data).Data);
        }
    });
}

export const baseAskFile = (api: string) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            const link = document.createElement('a');
            link.href = BACKEND + "api/" + api;
            link.target = "_blank";
            link.click();
            resolve();
        } catch (ex: any) {
            reject(JSON.parse(ex.response.data).Data);
        }
    });
}