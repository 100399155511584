import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import LandingPage from "./pages/landing/landing";
import StatusPage from "./pages/status/status";
import { SUBSITE } from "./constant/constants";

const RoutesManager = () => {
  return <Routes>
    <Route path="/status" element={<StatusPage />} />
    <Route path="*" element={<LandingPage />} />
  </Routes>
}

function App() {
  return <>
    <BrowserRouter basename={SUBSITE}>
      <RoutesManager />
    </BrowserRouter>
  </>
}

export default App;
