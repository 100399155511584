import { useState, useEffect } from "react";
import { baseGet } from "../../services/api";

function getRecap() {
    return baseGet("server/get/enabled")
}

function getClients() {
    return baseGet("server/recap")
}

export default function Status() {

    const [recap, setRecap] = useState<any[] | null>(null);
    const [recapOff, setRecapOff] = useState<any[] | null>(null);
    const [clients, setClients] = useState<any[] | null>(null);

    useEffect(() => {
        setInterval(() => {
            sync();
        }, 10000);
        sync();
    }, []);

    const sync = () => {
        getRecap()
            .then((list) => {
                setRecap(list.filter((x: any) => x.State == 'ENABLED').sort((a: any, b: any) => Number(a.Url.slice(-1)) > Number(b.Url.slice(-1)) ? 1 : -1))
                setRecapOff(list.filter((x: any) => x.State != 'ENABLED').sort((a: any, b: any) => Number(a.Url.slice(-1)) > Number(b.Url.slice(-1)) ? 1 : -1))
            })
            .catch((err) => { });
        getClients()
            .then((list) => setClients(list.sort((a: any, b: any) => Number(a.ServerUrl.slice(-1)) > Number(b.ServerUrl.slice(-1)) ? 1 : -1)))
            .catch((err) => { });
    }

    return <div className="container shadow br-30 p-5 pt-3 mt-5">

        <div className="d-flex flex-row justify-content-center mx-auto mb-3">
            <img src="/assets/images/brand/nebula/brand_color.png" id="img-0" className="mx-lg-3 opacity-0-1 active" style={{ height: '60px' }} />
            <img src="/assets/images/brand/brand_hospitality.png" id="img-1" className="mx-lg-3 opacity-0-1 active" style={{ height: '60px' }} />
        </div>

        <h3 className="mt-5">Stato server</h3>
        {
            (recap && recap?.length >= 0) || (recapOff && recapOff?.length >= 0) ?
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">State</th>
                            <th scope="col">Url</th>
                            <th scope="col">V# BE</th>
                            <th scope="col">V# FE</th>
                            <th scope="col">Connessi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recap?.map((element: any, index: number) => {
                                return <tr key={index}>
                                    <th scope="row">{element.ID}</th>
                                    <td>{element.Name}</td>
                                    <td className={element.State == 'ENABLED' ? 'text-success' : 'text-danger'}>{element.State}</td>
                                    <td><a href={element.Url} className="text-dark">{element.Url}</a></td>
                                    <td>{element.VersionBE ? element.VersionBE : "-"}</td>
                                    <td>{element.VersionFE ? element.VersionFE : "-"}</td>
                                    <td>{element.ConnQta}</td>
                                </tr>
                            })
                        }
                        {
                            recapOff?.map((element: any, index: number) => {
                                return <tr key={index}>
                                    <th scope="row">{element.ID}</th>
                                    <td>{element.Name}</td>
                                    <td className={element.State == 'ENABLED' ? 'text-success' : 'text-danger'}>{element.State}</td>
                                    <td><a href={element.Url} className="text-dark">{element.Url}</a></td>
                                    <td>{element.VersionBE ? element.VersionBE : "-"}</td>
                                    <td>{element.VersionFE ? element.VersionFE : "-"}</td>
                                    <td>{element.ConnQta}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                :
                <>
                    {
                        recap == null ? <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : <>-</>
                    }
                </>
        }

        <h3 className="mt-5">Stato utenti</h3>
        {
            clients && clients.length > 0 ?
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Code system</th>
                            <th scope="col">System</th>
                            <th scope="col">Username</th>
                            <th scope="col">Server</th>
                            <th scope="col">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clients?.map((element: any, index: number) => {
                                return <tr key={index}>
                                    <th scope="row">{element.Code}</th>
                                    <td>{element.Name}</td>
                                    <td>{element.Username}</td>
                                    <td>{element.ServerUrl}</td>
                                    <td>{element.Type}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                :
                <>
                    {
                        clients == null ? <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : <>-</>
                    }
                </>
        }

    </div>
}
