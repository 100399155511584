
import { useEffect, useState } from "react";
import { SUBSITE } from "../../constant/constants";
import { baseGet, basePost } from "../../services/api";
import { Link } from "react-router-dom";

export async function getLanguage(lang: string) {
    return baseGet("h/lang/sync?v=0&ln=" + lang + "&prj=BROKER")
}

export async function login(user: string, lang: string) {
    return basePost("auth/n/login?ln=" + lang, JSON.stringify({ user: user }));
}

export default function LandingPage() {

    const [isLoading, setIsLoading] = useState(true);

    const [username, setUsername] = useState("andrea");
    const [password, setPassword] = useState("Andrea123");
    const [systemcode, setSystemcode] = useState("BEXPO");
    const [language, setLanguage] = useState("it");
    const [disable, setDisabled] = useState(false);
    const [error, setError] = useState("");
    const [t, setT] = useState<any>();

    useEffect(() => {

        const l = (navigator.language || (navigator as any).userLanguage);
        const langFound = l && l.toString().toLowerCase().includes("it") ? "it" : "en"
        setLanguage(langFound);

        const v = localStorage.getItem("sys_code");
        setSystemcode(v ? v.toString() : "");
    }, []);

    useEffect(() => {

        getLanguage(language)
            .then((data: any) => { setT(JSON.parse(data)); })
            .finally(() => setIsLoading(false));

    }, [language]);

    const onChangeLanguage = async (newlang: any) => {
        setLanguage(newlang);
        setT(await getLanguage(newlang))
    }


    const animationOnLogin = (actionOnEnd: () => void) => {
        let ls = document.getElementById("login-success");
        let lb = document.getElementById("login-box");
        if (lb) {
            lb.addEventListener("animationend", (e) => {
                if (ls) {
                    ls.style['display'] = "block"; ls.addEventListener("animationend", (e) => {
                        setTimeout(() => { actionOnEnd() }, 1000);
                    });
                    ls.classList.add("animate__animated", "animate__fadeIn", "animate__delay-1s");
                    setDisabled(true);
                }
            });
            lb.classList.add("animate__animated", "animate__fadeOut");
        }
    }

    const onLogin = (e: any) => {

        e.preventDefault();

        const v = localStorage.setItem("sys_code", systemcode);

        let usermodel = btoa(username + "|" + password + "|" + systemcode);

        login(usermodel, language)
            .then((payload: any) => {


                console.log("onlogin");
                console.log(payload);
                console.log("ready");

                animationOnLogin(() => {
                    console.log("pok");
                    localStorage.setItem("sys_code", systemcode);
                    window.location.assign(payload);
                });
            })
            .catch((error: any) => {
                console.log("error");
                console.log(error);
                switch (error) {
                    case "servers-not-available":
                        setError("Il servizio non è disponibile");
                        break;
                    case "user-not-found":
                        setError("Utente non trovato");
                        break;
                }
                document.getElementById("btn-showerror")?.click();
            });
    }

    const fake = (code: string, username: string) => {

        let usermodel = btoa(username + "|" + "Andrea123" + "|" + code);

        login(usermodel, language)
            .then((payload: any) => {

                animationOnLogin(() => {
                    localStorage.setItem("sys_code", systemcode);
                    console.log(payload);
                    if (payload.status == "")
                        window.location.assign(payload.endpoint + "?ln=" + language + "&jwt=" + payload.token);
                });
            })
            .catch((error: any) => {
                console.log(error);
                switch (error) {
                    case "servers-not-available":
                        setError("Il servizio non è disponibile");
                        break;
                    case "user-not-found":
                        setError("Utente non trovato");
                        break;
                }
                document.getElementById("btn-showerror")?.click();
            });
    }

    const isBtnLoginEnabled = () => { return username.length > 0 && password.length > 0 && systemcode.length == 5 }

    if (isLoading)
        return <div className="vh-100 d-flex justify-content-center align-items-center">
            <img src={SUBSITE + "assets/gif/loading_accexi.gif"} style={{ width: 300 }} />
        </div>
    else
        return <div className="container p-5 shadow jumbotron-login position-relative">
            <div className="mx-autop-3">

                <div className="d-flex flex-row justify-content-center mb-5">
                    <img src="/assets/images/brand/accexi/brand_color.png" id="img-0" className="mx-lg-3 opacity-0-1" style={{ height: '60px' }} />
                    <img src="/assets/images/brand/brand_hospitality.png" id="img-1" className="mx-lg-3 opacity-0-1" style={{ height: '60px' }} />
                </div>
                <Link to="/status">status</Link>

                <div id="login-success" className="position-absolute" style={{ display: 'none', top: '55%', transform: 'translateX(-50%) translateY(-50%)', left: '50%' }}>
                    <h1 className="font-weight-bold text-primary"> benvenuto in Accexi </h1>
                </div>

                <fieldset disabled={disable}>

                    <div className="row" id="login-box">

                        <div className="col-12 col-xl-8">
                            <img src="./assets/images/brand/brand_content.png" className="w-100 br-30" />
                        </div>

                        <div className="col-12 col-xl-4">
                            <div className="d-flex flex-row justify-content-between mb-4 mt-4 mt-xl-0">
                                <h2 className="text-center accexi font-weight-normal">
                                    accexi
                                </h2>
                                <LanguageSelector lang={language} setNewLanguage={onChangeLanguage} />
                            </div>
                            <div className="">
                                <form onSubmit={onLogin}>
                                    <div className="form-group">
                                        <input type="text" placeholder={t['lbl_username']} onChange={e => setUsername(e.target.value)} value={username} className="form-control br-30 w-100" id="txtUsername" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" placeholder={t['lbl_password']} onChange={e => setPassword(e.target.value)} value={password} className="form-control br-30" id="txtPassword" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder={t['lbl_systemcode']} maxLength={5} onChange={e => setSystemcode(e.target.value)} value={systemcode} className={"form-control br-30 " + (systemcode.length > 0 ? "text-uppercase" : "")} />
                                    </div>
                                    <button type="button" className="btn btn-link" data-toggle="modal" data-target="#modal-credentials">
                                        {t['lbl_recovercredentials']}
                                    </button>
                                    <div className="text-right w-100 text-end mt-3">
                                        <button type="submit" className="btn btn-primary btn-lg py-3" disabled={!isBtnLoginEnabled()}>{t['btn_login']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="content my-5 d-none">

                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BEXPO", "andrea")}>BEXPO-andrea</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BEXPO", "andreap")}>BEXPO-andreap</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BEXPO", "fiera")}>BEXPO-fiera</button>

                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BDS15", "andrea")}>BDS15-andrea</button>

                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BMFQK", "hvillasorriso")}>BMFQK-hvillasorriso</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BD1S5", "rosina")}>bexpo-rosina</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BD1S5", "rosina2")}>bexpo-rosina2</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BD1S5", "rosina2")}>bexpo-rosina2</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BL060", "casaghiberti")}>BL060-casaghiberti</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BL060", "casaghiberti")}>BL060-casaghiberti</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BNXAP", "Imperialsport")}>BNXAP-Imperialsport</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BNBFA", "villinomilano")}>BNBFA-villinomilano</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BJK3F", "Saranda1")}>BJK3F-Saranda1</button>
                        <button type="button" className="btn btn-primary btn-lg py-3 m-3" onClick={e => fake("BIVQL", "BONELLO")}>BIVQL-BONELLO</button>

                    </div>
                </fieldset>
            </div>

            <div className="modal fade" id="modal-credentials" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ height: 400 }}>
                    <div className="modal-content br-30">
                        <div className="modal-header">
                            <h5 className="modal-title font-weight-bold h4" id="exampleModalLabel">{t['lbl_recovercredentials']}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body h5 mb-0 py-4">
                            {t['lbl_recovercredentialsin']} <a className="text-primary text-underline-none" href="https://hospitality.agb.it">Hospitality </a>
                        </div>
                    </div>
                </div>
            </div>

            <button type="button" id="btn-showerror" className="btn btn-link position-absolute d-none" style={{ top: '-500px' }} data-toggle="modal" data-target="#modal-error" />

            <div className="modal fade" id="modal-error" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ height: 400 }}>
                    <div className="modal-content br-30">
                        <div className="modal-header">
                            <h5 className="modal-title font-weight-bold h4">{t['lbl_loginerror']}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body h5 mb-0 py-4">
                            {error}
                        </div>
                    </div>
                </div>
            </div>

        </div >
}

function LanguageSelector(props: any) {

    const { lang, setNewLanguage } = props;

    if (lang == null || lang == "")
        return <div className="spinner-border" role="status"></div>

    return <div className="dropdown">

        <button className="btn btn-outline-link dropdown-toggle" type="button" data-toggle="dropdown">
            {lang == "it" ?
                <><i className="flag-icon flag-icon-it mr-2" />Italiano</>
                :
                <><i className="flag-icon flag-icon-us mr-2" />English</>
            }
        </button>

        <div className="dropdown-menu">
            {
                lang == "en" && <a className="dropdown-item" href="#" onClick={e => setNewLanguage("it")}><i className="flag-icon flag-icon-it mr-2" />Italiano</a>
            }
            {
                lang == "it" && <a className="dropdown-item" href="#" onClick={e => setNewLanguage("en")}><i className="flag-icon flag-icon-us mr-2" />English</a>
            }
        </div>
    </div>
}